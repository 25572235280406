@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oleo+Script:wght@400;700&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

@keyframes scrollAnimation {
  0% {
    transform: translateY(0%);
  }
  10% {
    transform: translateY(0%);
  }
  35% {
    transform: translateY(-25%);
  }
  45% {
    transform: translateY(-25%);
  }
  60% {
    transform: translateY(-50%);
  }
  75% {
    transform: translateY(-50%);
  }
  90% {
    transform: translateY(-75%);
  }
  100% {
    transform: translateY(-75%);
  }
}

.aboutUsContainer {
  box-shadow: 0px -15px 20px #f5f5f5;
}

.reviews {
  box-shadow: 0px -15px 20px #f5f5f5;
}

.hWrapper {
  h2 {
    font-family: "Open Sans", sans-serif;
    font-size: 30px;
    font-weight: 350;
  }

  .wrapper {
    .container {
      animation: scrollAnimation 6s infinite ease-in-out;

      .item {
        text-align: center;
        font-family: "Oleo Script", system-ui;
      }
    }
  }
}

.outer {
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);

  .inner {
    box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
      inset -4px 4px 6px -1px rgba(255, 255, 255, 0.7);
    background-color: #f9f9f9;

    .circle {
      stroke-dasharray: 472px;
    }
  }
}
