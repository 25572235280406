.review-section {
  margin-top: 30px;
  padding: 20px;
}

.review-card {
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 20px;
  text-align: center;
  //   transition: transform 0.6s ease-in-out;
  height: 100px;
}

// .review-card:hover {
//   transform: translateY(-10px);
// }

.review-card h5 {
  color: #666;
  font-size: 14px;
  margin-bottom: 10px;
}

.review-card h2 {
  color: #333;
  font-size: 20px;
  font-weight: 600;
}

.review-card-selected {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  padding: 40px;
  width: 350px;
  text-align: center;
  // position: relative;
}

.review-card-selected button {
  background-color: #ff6b6b;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  padding: 8px 16px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.review-card-selected button:hover {
  background-color: #ff3d3d;
}

.zero {
  // grid-column-start: 1;
  // grid-column-end: 3;
  // grid-row-start: 1;
  flex-basis: calc(30% - 1.25rem / 2);
}

.one {
  // grid-column-start: 3;
  // grid-column-end: 4;
  // grid-row-start: 1;
  flex-basis: calc(70% - 1.25rem / 2);
}

.two {
  // grid-column-start: 1;
  // grid-column-end: 2;
  // grid-row-start: 2;
  flex-basis: calc(70% - 1.25rem / 2);
}

.three {
  // grid-column-start: 2;
  // grid-column-end: 4;
  // grid-row-start: 2;
  flex-basis: calc(30% - 1.25rem / 2);
}
